<template>
  <v-container fluid>
    <v-card
      :loading="loading"
      :min-height="
        (contacts && contacts.length > 0) || loading ? '500px' : 'unset'
      "
    >
      <Loading_overlay v-model="loading" />
      <template v-if="contacts && contacts.length > 0">
        <v-card-title class="text-center">Our Team</v-card-title>
        <v-card-subtitle>{{ contacts.length }} People</v-card-subtitle>
        <v-row>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="6"
            sm="6"
            v-for="({ type, name, email, phone, pic_url }, index) in contacts"
            :key="index"
          >
            <v-card
              max-width="300"
              class="ma-auto"
              ripple
              elevation="6"
              :color="getRandomColor()"
              dark
              tile
            >
              <v-card-title style="background: rgba(0,0,0,0.2)">{{
                name
              }}</v-card-title>
              <v-card-subtitle class="text-center mt-1">
                <v-avatar size="120" color="rgba(0,0,0,0.3)">
                  <v-img v-if="pic_url" :src="pic_url" />
                  <v-icon v-else x-large>fas fa-user-tie</v-icon>
                </v-avatar>
              </v-card-subtitle>
              <v-card-text>
                <v-list three-line>
                  <v-list-item two-line>
                    <v-list-item-icon>
                      <v-icon>fas fa-briefcase</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Title</v-list-item-subtitle>
                      <v-list-item-title>{{ type }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line @click="mail(email)">
                    <v-list-item-icon>
                      <v-icon>fas fa-envelope</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                      <v-list-item-title>{{ email }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-icon>
                      <v-icon>fas fa-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Phone</v-list-item-subtitle>
                      <v-list-item-title>{{ phone }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-btn
                        v-if="phone"
                        icon
                        @click="copyTextToClipboard(phone)"
                        ><v-icon>fas fa-copy</v-icon></v-btn
                      ></v-list-item-action
                    >
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-alert v-if="selectedAss.AllAss" type="warning">
          No Contacts for this management company or an error occurred
        </v-alert>
        <no_data_to_show label="No Contacts for this client" v-else />
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import storage from "@/storage";
import { lauderLarken } from "@/plugins/axios";
import Loading_overlay from "@/components/loading_overlay";
export default {
  name: "contacts",
  components: {
    no_data_to_show: () => import("@/components/no_data_to_show"),
    Loading_overlay
  },
  data: () => ({
    loading: false,
    contact: {}
  }),
  computed: {
    contacts() {
      let contacts = [];
      if (!this.selectedAss || Object.keys(this.selectedAss).length === 0)
        return [];
      let ass = this.contact;
      if (ass.data) {
        ass = ass.data[0];
      }
      if (!ass || Object.keys(ass).length === 0) return [];
      if (ass["Collection Attorney"].trim()) {
        contacts.push({
          type: "Collection Attorney",
          name: ass["Collection Attorney"],
          email: ass["Collection Attorney Email"],
          phone: ass["Collection Attorney Phone"],
          pic_url: ass["Collection Attorney PIC URL"]
        });
      }
      if (ass["Litigation Paralegal"].trim()) {
        contacts.push({
          type: "Litigation Paralegal",
          name: ass["Litigation Paralegal"],
          email: ass["Litigation Paralegal Email"],
          phone: ass["Litigation Paralegal Phone"],
          pic_url: ass["Litigation Paralegal PIC URL"]
        });
      }
      if (ass["Pre-Litigation Paralegal"].trim()) {
        contacts.push({
          type: "Pre-Litigation Paralegal",
          name: ass["Pre-Litigation Paralegal"],
          email: ass["Pre-Litigation Paralegal Email"],
          phone: ass["Pre-Litigation Paralegal Phone"],
          pic_url: ass["Pre-Litigation Paralegal PIC URL"]
        });
      }
      if (ass["General Attorney"].trim()) {
        contacts.push({
          type: "General Attorney",
          name: ass["General Attorney"],
          email: ass["General Attorney Email"],
          phone: ass["General Attorney Phone"],
          pic_url: ass["General Attorney PIC URL"]
        });
      }
      return contacts;
    },
    ...mapGetters({
      selectedAss: "lauderGetSelectedAss",
      db: "laudergetdb",
      token: "customToken",
      loginState: "laudergetLoginState"
    })
  },
  methods: {
    mail(em) {
      if (em) {
        window.open(
          `mailto:${em}?subject=${this.selectedAss.Client_Associations}`
        );
      }
    },
    async fetchContacts(forceUpdate = false) {
      this.loading = true;
      try {
        if (!this.loginState) return;
        const searchForOld = await storage.getContactsData(this.token, this.db);
        if (searchForOld && !forceUpdate) {
          this.contact = searchForOld;
          // commit("contacts", searchForOld);
        } else {
          let { data } = await lauderLarken.getContacts(this.token);
          await storage.setContactsData(data.data[0], this.token, this.db);
          this.contact = data.data[0];
        }
      } catch (e) {
        let dt = await storage.getContactsData(this.token, this.db);
        if (dt) {
          this.contact = dt;
        } else {
          console.log(e);
        }
      }
      this.loading = false;
    }
  },
  watch: {
    selectedAss: {
      immediate: true,
      handler() {
        this.fetchContacts();
      }
    }
  }
};
</script>

<style scoped></style>
